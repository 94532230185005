import React from "react"

import css from "assets/css/components/common/list.module.styl"

//基本的なセル
export const Cell = ({label, children}) => {
    return(
        <div className={css.cell}>
            <div className={css.label}>{label}</div>
            <div className={css.content}>{children}</div>
        </div>
    )
}

//シンプルなセル
export const SimpleCell = ({children}) => {
    return(
        <div className={css.cell}></div>
    )
}

//リンクボタン
export const OpenLinkButton = ({href = '#',children}) => {
    return(
        <a href={href} target='_blank' className={css.button}>{children}</a>
    )
}

//リスト
export const PageList = ({children}) => (
    <div className={css.list}>
        {children}
    </div>
)
