    import React from "react"
import Image from "gatsby-image"
import { Link, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import SEO from "@components/seo"
import Footer from '@components/Footer'
import {PageList, SimpleCell, Cell, OpenLinkButton} from '@components/PageList'
import MobileStoreButton from '@components/MobileStoreButton'

import css from '@css/components/about/index.module.styl'
import pageCss from "@css/components/common/page.module.styl"

import AppSugerLabelSvg from "@images/about__lbl__app-suger.svg"
import AppCeoSvg from "@images/about__img__ceo.svg"
import * as Conf from "@src/conf.js"

const iosUrl = 'https://sugarlive.page.link/corp'
const androidUrl = 'https://sugarlive.page.link/corp-android'

class About extends React.PureComponent
{
    render(){
        const { data, location } = this.props
        const { about__img__app, bg01 } = data

        return (
            <div>
                <SEO title="ABOUT" />
                <BackgroundImage className={pageCss.bg} fluid={bg01.childImageSharp.fluid}/>
                <main className={css.container}>
                    <div className={pageCss.container}>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <p className={pageCss.label}>ABOUT</p>
                                <h2 className={pageCss.title}>
                                    <span>あこがれの</span><span>あの人と</span><br/>
                                    <span>ライブで</span><span>話せる、</span><span className={pageCss.textTunagal}>つながる</span>。
                                </h2>
                            </div>
                            <div className={pageCss.content}>
                                <div className={pageCss.paragraphs}>
                                    <p>
                                        「あこがれのあの人から、テレビ電話がかかってくる！」<br/>
                                        いままでにない画期的な双方向ライブ配信アプリ「SUGAR」を展開中。
                                    </p>
                                    <p>
                                        ファンには強烈なおどろきをお届けし、アーティストは安心して利用できる、<br/>
                                        世界唯一のプラットフォームです。
                                    </p>
                                </div>
                            </div>
                        </section>
                        <section className={pageCss.section}>
                            <div className={pageCss.header}>
                                <p className={pageCss.label}>SERVICE</p>
                            </div>
                            <div className={pageCss.content}>
                                <div className={pageCss.service}>
                                    <div className={pageCss.thumbnail}>
                                        <Image fluid={about__img__app.childImageSharp.fluid}/>
                                    </div>
                                    <div className={pageCss.detail}>
                                        <h3 className={pageCss.title}><AppSugerLabelSvg/></h3>
                                        <div className={pageCss.description}>
                                            <p>
                                                憧れのあの人と生電話！双方向ライブ配信アプリSUGAR（シュガー）。
                                                今までにない、双方向ライブ配信アプリ！憧れのあの人と今すぐつながろう！SUGAR（シュガー）は、誰でも知ってる憧れの人たちと直接話せる、画期的な『双方向LIVE配信アプリ』です。
                                            </p>
                                            <div className={pageCss.storeLinks}>
                                                <MobileStoreButton
                                                    store="ios"
                                                    url={iosUrl}
                                                    linkProps={{ title: 'App Store' }}
                                                />
                                                <MobileStoreButton
                                                    store="android"
                                                    url={androidUrl}
                                                    linkProps={{ title: 'Google Play' }}
                                                />
                                            </div>
                                        </div>
                                        <div className={pageCss.links}>
                                            <ul>
                                                <li><Link to={Conf.Links.app_privacy}>
                                                    プライバシーポリシー
                                                </Link></li>
                                                <li><Link to={Conf.Links.app_terms}>
                                                    利用規約
                                                </Link></li>
                                                {/*
                                                <li><Link to={Conf.Links.app_tokutei}>
                                                    特定商取引法に基づく表記
                                                </Link></li>
                                                <li><Link to={Conf.Links.app_shikin}>
                                                    資金決済に関する法律に基づく表示
                                                </Link></li>
                                                */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className={pageCss.section}>
                            {/*<div className={pageCss.header}>
                                <p className={pageCss.label}>INFOMATION</p>
                            </div>*/}
                            <div className={pageCss.content}>
                                <PageList>
                                    <Cell label="社名">SUGAR株式会社</Cell>
                                    <Cell label="設立">2017年6月</Cell>
                                    {/*<Cell label="資本金">2億1,699万円（資本準備金含む）</Cell>*/}
                                    {/*<Cell label="代表者">
                                        <AppCeoSvg style={{height:16, width: "auto"}}/>
                                    </Cell>*/}
                                    <Cell label="事業内容">スマートフォンアプリ「SUGAR」の開発・運営</Cell>
                                    <Cell label="所在地">
                                        <p>〒107-0061</p>
                                        <p>東京都港区北青山3-6-7 青山パラシオタワー11階</p>
                                        <p>
                                            <OpenLinkButton href="https://goo.gl/maps/5MTY9RFXQEeRwmoe8">
                                                Open Google Map
                                            </OpenLinkButton>
                                        </p>
                                    </Cell>
                                </PageList>
                            </div>
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}

export default About

export const query = graphql`
    query {
        about__img__app : file(relativePath: { eq: "about__img__app@2x.png" }) {
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bg01 : file(relativePath: { eq: "global__img__sugar-bg-01@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 842) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
